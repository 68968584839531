<template>
  <v-btn
    :class="`${classes}`"
    :disabled="disabled"
    :title="lang('ChecklistBuilder.titles.add_actions')"
    data-quick-button-action-view="add_actions"
    type="button"
    small
  >
    <v-icon>
      mdi-format-list-numbered
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'ChecklistCellButtonTypeActions',
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'buttontype',
    'dataViewer',
    'data'
  ],
  computed: {
    classes() {
      if (this.dataViewer.requires_action) {
        return `${this.state.classes} required-actions`
      }
      if (
        /[0-9]+/.test(this.dataViewer.actions) ||
        /[0-9]+/.test(this.cell.actions)
      ) {
        return `${this.state.classes} assigned-actions`
      }
      return this.state.classes
    },
    disabled() {
      const builder = this.data.ChecklistBuilder
      return this.state.disabled || !builder.getSetting('ONLINE')
    }
  },
  async created() {
    if (this.dataViewer.requires_action === null) {
      const builder = this.data.ChecklistBuilder
      const dataEditor = this.cell
      const dataViewer = this.dataViewer
      const events = []
      const temporaryEvents = dataEditor.settings.value_events || []
      for (let i = 0, event; (event = temporaryEvents[i++]);) {
        if (event.action === 'requires_action') {
          events.push(event)
        }
      }
      if (events.length) {
        let value = ''
        if (Array.isArray(dataViewer.value)) {
          value = dataViewer.value[0]
        } else {
          value = builder.getLocalization(dataEditor, 'preset_value')
        }
        if (await builder.evalTerms({
          Events: events,
          DataViewer: dataViewer,
          DataEditor: dataEditor,
          Value: value,
          Muted: true,
          CloneIndex: 0,
          ChecklistIndex: this.checklist.index
        }).requires_action) {
          dataViewer['requires_action'] = true
        } else {
          dataViewer['requires_action'] = false
        }
      }
    }
  },
  methods: {
    lang(langKey) {
      const lang = this.data.ChecklistBuilder.getSetting('TRANSLATION')
      return lang(langKey)
    }
  }
}
</script>

<style>
  .checklist-viewer .cell-button-collection .assigned-actions i {
    color: #0a0;
  }

  .checklist-viewer .cell-button-collection .required-actions i {
    color: #f00;
  }
</style>
